/* eslint-disable react-hooks/rules-of-hooks */
import { Suspense, useContext, useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { NetworkState } from 'src/constants/network-state'
import { useAuth } from '../auth'
import { persistAuth } from '../auth/utils/persistAuth'
import SuspenseLoader from '../components/Temp'
import { AbilityContext } from '../context/canContext'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { IUserSlice } from '../redux/user/userSlice.contracts'

export default function AuthGuard({ children }: { children: JSX.Element }) {
    try {
        const userSlice: IUserSlice = useAppSelector((state) => state.user)
        const abilityRoleSlice = useAppSelector((state) => state.abilityRole)
        const { applicationGroupNetworkStatus } = useAppSelector(
            (state) => state.applicationGroup
        )
        const ability = useContext(AbilityContext)

        const location = useLocation()
        const {
            loading,
            error,
            user,
            emailVerified,
            isAuthenticated,
            getTokens,
            logout,
        } = useAuth()
        const navigate = useNavigate()
        const dispatch = useAppDispatch()

        useEffect(() => {
            const prep = async () => {
                try {
                    const tokens = await getTokens()
                    const userSlice: IUserSlice = {
                        user: user,
                        status: NetworkState.AUTHENTICATED,
                        accessToken: tokens?.accessToken ?? null,
                        refreshToken: tokens?.refreshToken ?? null,
                        error: null,
                    }
                    dispatch(persistAuth({ userAuth: userSlice }))
                } catch (error) {
                    console.log(error)
                    toast.error(
                        'There has been an error getting authentication details. Please try logging in'
                    )
                    navigate('/auth/login')
                }
            }

            if (!loading && user) {
                prep()
            }

            if (error) {
                console.log(error)
                toast.error(
                    'There has been an error getting authentication details. Please try logging in'
                )
                navigate('/auth/login')
            }
        }, [user, loading, error])

        if (!isAuthenticated && !user && !loading && !loading) {
            // Redirect users to the login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.
            if (!userSlice || !userSlice?.user) {
                return (
                    <Navigate
                        to="/auth/login"
                        state={{ from: location }}
                        replace
                    />
                )
            }
        }

        if (
            abilityRoleSlice.status === NetworkState.ERROR ||
            applicationGroupNetworkStatus.applicationGroups ===
                NetworkState.ERROR
        ) {
            logout()
            return <Navigate to="/auth/login" />
        }

        if (
            loading ||
            abilityRoleSlice.status !== NetworkState.SUCCESS ||
            applicationGroupNetworkStatus.applicationGroups !==
                NetworkState.SUCCESS ||
            !ability
        ) {
            return (
                <Suspense fallback={<SuspenseLoader />}>
                    <SuspenseLoader />
                </Suspense>
            )
        }

        if ((!loading || !loading) && !emailVerified) {
            return (
                <Navigate
                    to="/status/unverified"
                    state={{ from: location }}
                    replace
                />
            )
        }
    } catch (error) {
        //Route to page if anything failed here
        console.log(error)
        return <Navigate to="/status/500" replace />
    }

    return children
}
