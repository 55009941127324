export enum Endpoint {
    POKEMON = 'Pokemon',
    APPLICATION = 'Application',
    APPLICATION_GROUP = 'ApplicationGroup',
    ACCOUNT = 'Account',
    TARIFFS = 'Tariff',
    METERS = 'Meter',
    METER_POINT = 'MeterPoint',
    ABILITY_ROLE = 'AbilityRole',
    XERO_INTEGRATION = 'XeroIntegration',
    XERO_TAX_RATE = 'XeroTaxRate',
    XERO_ACCOUNT = 'XeroAccount',
    UTILITY_DATA = 'UtilityData',
    UTILITY = 'Utility',
    TARIFF_STRUCTURE = 'TariffStructure',
    TRANSACTION = 'Transaction',
    PAYMENT = 'Payment',
    USER = 'User',
    MODEM = 'Modem',
    REPORT = 'Report',
    REPORT_LAYOUT = 'ReportLayout',
    RECON = 'Recon',
    NOTIFICATION = 'Notification',
    PUSH_NOTIFICATION = 'PushNotification',
    MODBUS_COMMAND = 'MODBUS_COMMAND',
    MODBUS_RESPONSE = 'ModbusResponse',
    DASHBOARD = 'DASHBOARD',
    BILL_RUN = 'BillRun',
    EMAIL_INTEGRATION = 'EmailIntegration',
    EMAIL_WHITELIST = 'EmailWhitelist',
    MANUAL_READINGS = 'ManualReadings',
    DEBT = 'Debt',
    ALERT = 'Alert',
    AUDIT_LOG = 'AuditLog',
    METER_LOG = 'MeterLog',
    MDMS_LOG = 'MDMSLog',
}

//TODO ENDPOINT_URLS and ENDPOINT TAGS can be the same

export enum ENDPOINT_URLS {
    XERO_INTEGRATION = 'xero-integration',
    METER = 'meter',
    DEBT = 'debt-recovery-transaction',
    METER_POINT = 'meter-point',
    ALERT = 'alert',
    APPLICATION = 'application',
    APPLICATION_GROUP = 'application-group',
    ACCOUNT = 'account',
    ABILITY_ROLE = 'ability-role',
    UTILITY_DATA = 'utility-data',
    NOTIFICATION = 'notification',
    UTILITY = 'utility',
    TARIFF = 'tariff',
    TARIFF_STRUCTURE = 'tariff-structure',
    TRANSACTION = 'transaction',
    PAYMENT = 'payment',
    USER = 'user',
    GATEWAY = 'gateway',
    PUSH_NOTIFICATION = 'push-notification',
    RECON = 'recon',
    REPORT = 'report',
    REPORT_LAYOUT = 'report-layout',
    MODBUS_COMMAND = 'modbus-command',
    MODBUS_RESPONSE = 'modbus-response-log',
    BILL_RUN = 'bill-run',
    EMAIL_INTEGRATION = 'email-integration-log',
    EMAIL_WHITELIST = 'email-integration-whitelist',
    MANUAL_READINGS = 'manual-readings',
    AUDIT_LOG = 'audit-log',
    SENSOR = 'sensor',
    SENSOR_DATA = 'sensor-data',
    METER_LOG = 'meter-log',
    MDMS_LOG = 'mdms-log',
}

export const API_ROUTES = {
    utilityData: {
        base: ENDPOINT_URLS.UTILITY_DATA,
        aggregate: `${ENDPOINT_URLS.UTILITY_DATA}/aggregateP1ReadingsByBucket`,
        p1Split: `${ENDPOINT_URLS.UTILITY_DATA}/aggregateP1Split`,
    },
    recon: {
        base: ENDPOINT_URLS.RECON,
    },
    report: {
        base: ENDPOINT_URLS.REPORT,
    },
    reportLayout: {
        base: ENDPOINT_URLS.REPORT_LAYOUT,
    },
}

export const TAG_TYPES: readonly string[] = Object.values(Endpoint)

export enum TagId {
    PARTIAL_LIST = 'PARTIAL-LIST',
}

export enum API_METHODS {
    POST = 'POST',
    DELETE = 'DELETE',
    GET = 'GET',
    PATCH = 'PATCH',
    PUT = 'PUT',
}

export const XERO_URLS = [
    '/api/xero/accounts',
    '/api/xero/tax-rates',
    '/api/connect-url',
    '/api/xero/contacts',
]
