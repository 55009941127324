import { Bolt, HideSource, QuestionMark, SolarPower } from '@mui/icons-material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CloudIcon from '@mui/icons-material/Cloud'
import OpacityIcon from '@mui/icons-material/Opacity'
import { Chip, ChipProps, Tooltip } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { blue, green, grey, purple, yellow } from '@mui/material/colors'
import dayjs from 'dayjs'
import React from 'react'
import { BsBatteryCharging } from 'react-icons/bs'
import { CiPlug1 } from 'react-icons/ci'
import { GiHole, GiTap } from 'react-icons/gi'
import { TagType, UtilityType } from 'src/constants'
import { MeterRuleTimeframes } from 'src/constants/meters'
import { capitalize, getUtilityUnits } from 'src/utils/formatters'
import { MeterRuleClass } from 'switch-shared/types/meter'

interface UtilityChipProps extends ChipProps {
    type?: UtilityType
    consumption?: number
}

export const UtilityTypeAvatar = ({ type, size }: UtilityChipProps) => {
    switch (type) {
        case UtilityType.ELECTRICITY:
            return (
                <Avatar
                    sx={{
                        bgcolor: yellow[100],
                        width:
                            size === 'medium' ? 40 : size === 'small' ? 33 : 27,
                        height:
                            size === 'medium' ? 40 : size === 'small' ? 33 : 27,
                        mr: 1,
                        mb: size === 'small' ? 0 : 1,
                    }}
                >
                    <Bolt
                        style={{ color: yellow[800] }}
                        fontSize={size ?? 'small'}
                    />
                </Avatar>
            )
        case UtilityType.FIXED_FEE:
            return (
                <Avatar
                    sx={{
                        bgcolor: green[100],
                        width:
                            size === 'medium' ? 40 : size === 'small' ? 33 : 27,
                        height:
                            size === 'medium' ? 40 : size === 'small' ? 33 : 27,
                        mr: 1,
                        mb: size === 'small' ? 0 : 1,
                    }}
                >
                    <AttachMoneyIcon
                        style={{ color: green[800] }}
                        fontSize={size ?? 'small'}
                    />
                </Avatar>
            )
        case UtilityType.GAS:
            return (
                <Avatar
                    sx={{
                        bgcolor: purple[100],
                        width:
                            size === 'medium' ? 40 : size === 'small' ? 33 : 27,
                        height:
                            size === 'medium' ? 40 : size === 'small' ? 33 : 27,
                        mr: 1,
                        mb: size === 'small' ? 0 : 1,
                    }}
                >
                    <CloudIcon
                        style={{ color: purple[800] }}
                        fontSize={size ?? 'small'}
                    />
                </Avatar>
            )
        case UtilityType.WATER:
            return (
                <Avatar
                    sx={{
                        bgcolor: blue[100],
                        width:
                            size === 'medium' ? 40 : size === 'small' ? 33 : 27,
                        height:
                            size === 'medium' ? 40 : size === 'small' ? 33 : 27,
                        mr: 1,
                        mb: size === 'small' ? 0 : 1,
                    }}
                >
                    <OpacityIcon
                        style={{ color: blue[500] }}
                        fontSize={size ?? 'small'}
                    />
                </Avatar>
            )
        default:
            return (
                <Avatar
                    sx={{
                        bgcolor: blue[100],
                        width:
                            size === 'medium' ? 40 : size === 'small' ? 33 : 27,
                        height:
                            size === 'medium' ? 40 : size === 'small' ? 33 : 27,
                        mr: 1,
                        mb: size === 'small' ? 0 : 1,
                    }}
                >
                    <OpacityIcon
                        style={{ color: blue[500] }}
                        fontSize={size ?? 'small'}
                    />
                </Avatar>
            )
    }
}

export const UsageTextChip: React.FC<UtilityChipProps> = (
    props: UtilityChipProps
) => {
    const { type, size, ...rest } = props
    switch (type) {
        case UtilityType.ELECTRICITY:
            return (
                <Chip
                    {...rest}
                    label={capitalize(type)}
                    sx={{
                        backgroundColor: yellow[100],
                        mr: 1,
                        color: yellow[800],
                    }}
                    avatar={
                        <Avatar
                            sx={{
                                bgcolor: yellow[100],
                                width:
                                    size === 'medium'
                                        ? 40
                                        : size === 'small'
                                          ? 33
                                          : 27,
                                height:
                                    size === 'medium'
                                        ? 40
                                        : size === 'small'
                                          ? 33
                                          : 27,
                                ml: 0.5,
                                mb: size === 'small' ? 0 : 1,
                            }}
                        >
                            <Bolt
                                style={{ color: yellow[800] }}
                                fontSize={size ?? 'small'}
                            />
                        </Avatar>
                    }
                />
            )
        case UtilityType.FIXED_FEE:
            return (
                <Chip
                    {...rest}
                    label={capitalize(type)}
                    sx={{
                        backgroundColor: green[100],
                        mr: 1,
                        color: green[800],
                    }}
                    avatar={
                        <Avatar
                            sx={{
                                bgcolor: green[100],
                                width:
                                    size === 'medium'
                                        ? 40
                                        : size === 'small'
                                          ? 33
                                          : 27,
                                height:
                                    size === 'medium'
                                        ? 40
                                        : size === 'small'
                                          ? 33
                                          : 27,
                                ml: 0.5,
                                mb: size === 'small' ? 0 : 1,
                            }}
                        >
                            <AttachMoneyIcon
                                style={{ color: green[800] }}
                                fontSize={size ?? 'small'}
                            />
                        </Avatar>
                    }
                />
            )
        case UtilityType.GAS:
            return (
                <Chip
                    {...rest}
                    label={capitalize(type)}
                    sx={{
                        backgroundColor: purple[100],
                        mr: 1,
                        color: purple[800],
                    }}
                    avatar={
                        <Avatar
                            sx={{
                                bgcolor: purple[100],
                                width:
                                    size === 'medium'
                                        ? 40
                                        : size === 'small'
                                          ? 33
                                          : 27,
                                height:
                                    size === 'medium'
                                        ? 40
                                        : size === 'small'
                                          ? 33
                                          : 27,
                                ml: 0.5,
                                mb: size === 'small' ? 0 : 1,
                            }}
                        >
                            <CloudIcon
                                style={{ color: purple[800] }}
                                fontSize={size ?? 'small'}
                            />
                        </Avatar>
                    }
                />
            )
        case UtilityType.WATER:
            return (
                <Chip
                    {...rest}
                    label={capitalize(type)}
                    sx={{ backgroundColor: blue[100], mr: 1, color: blue[500] }}
                    avatar={
                        <Avatar
                            sx={{
                                bgcolor: blue[100],
                                width:
                                    size === 'medium'
                                        ? 40
                                        : size === 'small'
                                          ? 33
                                          : 27,
                                height:
                                    size === 'medium'
                                        ? 40
                                        : size === 'small'
                                          ? 33
                                          : 27,
                                ml: 0.5,
                                mb: size === 'small' ? 0 : 1,
                            }}
                        >
                            <OpacityIcon
                                style={{ color: blue[500] }}
                                fontSize={size ?? 'small'}
                            />
                        </Avatar>
                    }
                />
            )
        default:
            return <Chip label="Utility" {...rest} />
    }
}

export const UtilityTypeBadge: React.FC<UtilityChipProps> = ({
    type,
    size,
}) => {
    switch (type) {
        case UtilityType.ELECTRICITY:
            return (
                <Tooltip title="Electricity">
                    <Avatar
                        sx={{
                            bgcolor: yellow[100],
                            width:
                                size === 'medium'
                                    ? 40
                                    : size === 'small'
                                      ? 33
                                      : 27,
                            height:
                                size === 'medium'
                                    ? 40
                                    : size === 'small'
                                      ? 33
                                      : 27,
                            ml: 0.5,
                            mb: size === 'small' ? 0 : 1,
                        }}
                    >
                        <Bolt
                            style={{ color: yellow[800] }}
                            fontSize={size ?? 'small'}
                        />
                    </Avatar>
                </Tooltip>
            )
        case UtilityType.FIXED_FEE:
            return (
                <Tooltip title="Fixed fee">
                    <Avatar
                        sx={{
                            bgcolor: green[100],
                            width:
                                size === 'medium'
                                    ? 40
                                    : size === 'small'
                                      ? 33
                                      : 27,
                            height:
                                size === 'medium'
                                    ? 40
                                    : size === 'small'
                                      ? 33
                                      : 27,
                            ml: 0.5,
                            mb: size === 'small' ? 0 : 1,
                        }}
                    >
                        <AttachMoneyIcon
                            style={{ color: green[800] }}
                            fontSize={size ?? 'small'}
                        />
                    </Avatar>
                </Tooltip>
            )
        case UtilityType.GAS:
            return (
                <Tooltip title="Gas">
                    <Avatar
                        sx={{
                            bgcolor: purple[100],
                            width:
                                size === 'medium'
                                    ? 40
                                    : size === 'small'
                                      ? 33
                                      : 27,
                            height:
                                size === 'medium'
                                    ? 40
                                    : size === 'small'
                                      ? 33
                                      : 27,
                            ml: 0.5,
                            mb: size === 'small' ? 0 : 1,
                        }}
                    >
                        <CloudIcon
                            style={{ color: purple[800] }}
                            fontSize={size ?? 'small'}
                        />
                    </Avatar>
                </Tooltip>
            )
        case UtilityType.WATER:
            return (
                <Tooltip title="Water">
                    <Avatar
                        sx={{
                            bgcolor: blue[50],
                            width:
                                size === 'medium'
                                    ? 40
                                    : size === 'small'
                                      ? 33
                                      : 27,
                            height:
                                size === 'medium'
                                    ? 40
                                    : size === 'small'
                                      ? 33
                                      : 27,
                            ml: 0.5,
                            mb: size === 'small' ? 0 : 1,
                        }}
                    >
                        <OpacityIcon
                            style={{ color: blue[500] }}
                            fontSize={size ?? 'small'}
                        />
                    </Avatar>
                </Tooltip>
            )
        default:
            return (
                <Avatar
                    sx={{
                        bgcolor: grey[50],
                        width:
                            size === 'medium' ? 40 : size === 'small' ? 33 : 27,
                        height:
                            size === 'medium' ? 40 : size === 'small' ? 33 : 27,
                        ml: 0.5,
                        mb: size === 'small' ? 0 : 1,
                    }}
                >
                    <QuestionMark
                        style={{ color: grey[500] }}
                        fontSize={size ?? 'small'}
                    />
                </Avatar>
            )
    }
}

export const RuleInChips = ({
    rule,
    utilityType,
}: {
    rule: MeterRuleClass
    utilityType?: UtilityType
}) => {
    return (
        <>
            IF{' '}
            <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={rule.aggregator.toUpperCase()}
            />{' '}
            OF USAGE OVER{' '}
            <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={
                    MeterRuleTimeframes.find(
                        (ele) => ele.duration == rule.timeFrame
                    )?.label
                }
            />{' '}
            IS{' '}
            <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={
                    rule.operator === 'lt'
                        ? '<'
                        : rule.operator === 'gt'
                          ? '>'
                          : rule.operator === 'lte'
                            ? '≤'
                            : '≥'
                }
            />{' '}
            {'  '}
            <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={`${rule.threshold / 1000} ${getUtilityUnits(
                    utilityType,
                    true
                )}`}
            />
            {typeof rule.ruleDatesAndTimes === 'undefined' ||
            !rule.ruleDatesAndTimes.length
                ? null
                : ' ON '}
            {typeof rule.ruleDatesAndTimes === 'undefined' ||
            !rule.ruleDatesAndTimes
                ? null
                : rule.ruleDatesAndTimes.map((element, index) => (
                      <Chip
                          key={index}
                          size="small"
                          variant="outlined"
                          color="primary"
                          label={`${element.day} (${dayjs(
                              element.startTime
                          ).format('HH:mm')}-${dayjs(element.endTime).format(
                              'HH:mm'
                          )})`}
                      />
                  ))}
        </>
    )
}

export const MeterTagIcon = (params: { type: string }) => {
    switch (params.type) {
        case TagType.SOLAR:
            return (
                <Avatar
                    sx={{
                        bgcolor: yellow[100],
                        width: 25,
                        height: 25,
                        ml: 0.5,
                    }}
                >
                    <SolarPower
                        style={{ color: yellow[800], height: 15, width: 15 }}
                        sx={{ mr: 2 }}
                    />
                </Avatar>
            )
        case TagType.GRID:
            return (
                <Avatar
                    sx={{
                        bgcolor: yellow[100],
                        width: 25,
                        height: 25,
                        ml: 0.5,
                    }}
                >
                    <CiPlug1 color={yellow[800]} size={15} />
                </Avatar>
            )
        case TagType.BATTERY:
            return (
                <Avatar
                    sx={{
                        bgcolor: yellow[100],
                        width: 25,
                        height: 25,
                        ml: 0.5,
                    }}
                >
                    <BsBatteryCharging color={yellow[800]} size={15} />
                </Avatar>
            )
        case TagType.MUNICIPAL:
            return (
                <Avatar
                    sx={{ bgcolor: blue[50], width: 25, height: 25, ml: 0.5 }}
                >
                    <GiTap color={blue[500]} size={15} />
                </Avatar>
            )
        case TagType.UNMETERED:
            return (
                <Avatar
                    sx={{ bgcolor: blue[50], width: 25, height: 25, ml: 0.5 }}
                >
                    <HideSource
                        style={{ color: blue[500], height: 15, width: 15 }}
                    />
                </Avatar>
            )
        case TagType.BOREHOLE:
            return (
                <Avatar
                    sx={{ bgcolor: blue[50], width: 25, height: 25, ml: 0.5 }}
                >
                    <GiHole color={blue[500]} size={17} />
                </Avatar>
            )
        default:
            return <Chip label="Utility" />
    }
}
